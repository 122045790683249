<template>
  <v-row justify="center">
    <v-form ref="editChild">
      <v-card width="100%">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="cancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ getTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>

        <v-container class="grey lighten-5">
          <fieldset
            v-for="(area, idx) in formData"
            :key="'f' + idx"
            class="pa-2 ma-2"
          >
            <legend>
              <v-btn
                class="mx-2"
                fab
                x-small
                color="primary"
                style="cursor: default"
                >{{ idx + 1 }}</v-btn
              >{{ area.fieldset }}
            </legend>
            <v-row v-if="Array.isArray(area[area.subNode])">
              <v-col
                v-for="(item, index) in area[area.subNode]"
                :key="'c' + index"
                cols="12"
                lg="3"
                md="3"
                sm="6"
              >
                <text-field
                  v-if="item.type === 'text'"
                  :key="'tf' + keyCounter"
                  :field.sync="child[area.subNode][item.value]"
                  :label="item.label"
                  :maxlength="item.maxlength"
                  :rules="item.rules"
                  :not-required="item.notRequired"
                  :class="item.notRequired ? 'ml-8' : ''"
                />
                <v-select
                  v-if="item.type === 'select'"
                  v-model="child[area.subNode][item.value]"
                  :items="item.selItems"
                  item-text="text"
                  item-value="val"
                  :label="item.label"
                  outlined
                  dense
                  :rules="getRules(item.rules)"
                  :required="!item.notRequired"
                  :disabled="item.disabled"
                >
                  <v-icon
                    v-if="!item.notRequired"
                    slot="prepend"
                    :color="
                      child[area.subNode][item.value] === null ? 'red' : 'green'
                    "
                    >mdi-star</v-icon
                  >
                </v-select>
                <v-checkbox
                  v-if="item.type === 'checkbox'"
                  v-model="child[area.subNode][item.value]"
                  :label="item.label"
                  hide-details
                ></v-checkbox>
                <check-box-with-input
                  v-if="item.type === 'checkboxWithInput'"
                  :label="item.label"
                  :maxlength="item.maxlength"
                  outlined
                  dense
                ></check-box-with-input>
              </v-col>
            </v-row>
            <div v-else>
              <div
                v-for="(item1, index1) in area[area.subNode].subNodes"
                :key="'c' + index1"
              >
                <v-row align="center" class="mt-2">
                  <v-divider class="mx-4" style="max-width: 50px"></v-divider>
                  <span class="text-caption">{{ item1.header }}</span>
                  <v-divider class="mx-4"></v-divider>
                </v-row>
                <v-row>
                  <v-col
                    v-for="(item, index) in item1[item1.dataName]"
                    :key="'r' + index"
                    cols="12"
                    lg="3"
                    md="3"
                    sm="6"
                  >
                    <text-field
                      v-if="item.type === 'text'"
                      :field.sync="
                        child[area.subNode][item1.dataName][item.value]
                      "
                      :label="item.label"
                      :maxlength="item.maxlength"
                      :rules="item.rules"
                      :not-required="item.notRequired"
                      class="ml-8"
                    />
                    <v-select
                      v-if="item.type === 'select'"
                      v-model="child[area.subNode][item1.dataName][item.value]"
                      :items="item.selItems"
                      item-text="text"
                      item-value="val"
                      :label="item.label"
                      outlined
                      dense
                      :required="!item.notRequired"
                      class="ml-8"
                      :disabled="item.disabled"
                    >
                      <v-icon
                        v-if="!item.notRequired"
                        slot="prepend"
                        :color="
                          child[area.subNode][item1.dataName][item.value] ===
                          null
                            ? 'red'
                            : 'green'
                        "
                        >mdi-star</v-icon
                      >
                    </v-select>
                    <v-checkbox
                      v-if="item.type === 'checkbox'"
                      v-model="child[area.subNode][item1.dataName][item.value]"
                      :label="item.label"
                      hide-details
                      class="ml-8"
                    ></v-checkbox>
                    <check-box-with-input
                      v-if="item.type === 'checkboxWithInput'"
                      :my-obj.sync="
                        child[area.subNode][item1.dataName][item.value]
                      "
                      :label="item.label"
                      :maxlength="item.maxlength"
                      class="ml-8"
                    ></check-box-with-input>
                  </v-col>
                </v-row>
              </div>
            </div>
          </fieldset>
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="grey" @click="cancel"> Abbrechen </v-btn>
          <v-btn dark color="green" @click="validateForm"> Speichern </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-row>
</template>

<script>
import {
  CONST_FORM_DATA,
  CONST_RULES_MAP
} from '../../../plugins/constants/Constants';
import { getYears } from '../../../plugins/functions/StaticFunctions';
import { Registrant } from '../../../plugins/classes/Classes';

import TextField from '../../elements/TextField.vue';
import CheckBoxWithInput from '../../elements/CheckBoxWithInput.vue';

const rulesMap = CONST_RULES_MAP;

export default {
  components: {
    TextField,
    CheckBoxWithInput
  },

  data() {
    return {
      formData: CONST_FORM_DATA,
      keyCounter: 0
    };
  },

  computed: {
    child() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.keyCounter = +1;
      if (this.$router.currentRoute.name === 'Teilnehmer bearbeiten') {
        const allParticipants = this.$store.state.participantsModule
          .participants;
        const child = allParticipants.find(e => e.id === this.$route.params.id);
        return this.formatChildIncoming(child);
      }
      return JSON.parse(JSON.stringify(Registrant.getNew()));
    },

    getTitle() {
      return this.$router.currentRoute.name === 'Teilnehmer anlegen'
        ? 'Teilnehmer anlegen'
        : 'Teilnehmer bearbeiten';
    },

    isFormValid() {
      return this.$refs.editChild ? this.$refs.editChild.validate() : false;
    }
  },

  methods: {
    getYears,

    debugIt(x, y) {
      console.log(x + y);
      return 'xy';
    },

    getRules(rule) {
      return rulesMap.get(rule);
    },

    cancel() {
      this.$store.commit('MUT_IS_CHILDREN_LIST', true);
      this.$store.commit('MUT_IS_CHILD_SELECTED', true);
      this.$router.push(`/participants/display/${this.child.id}`);
    },

    validateForm() {
      if (!this.$refs.editChild.validate()) {
        // missing information
        this.$root.$confirm
          .open({
            title: 'Muss-Eingaben unvollständig',
            msg: 'Trotzdem speichern (weil Du Admin bist)?'
          })
          .then(response => {
            if (response) {
              this.saveChild();
            }
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.saveChild();
      }
    },

    saveChild() {
      const action =
        this.$router.currentRoute.name === 'Teilnehmer anlegen'
          ? 'ACT_CREATE_PARTICIPANT'
          : 'ACT_UPDATE_PARTICIPANT';
      const msg =
        this.$router.currentRoute.name === 'Teilnehmer anlegen'
          ? 'Teilnehmer angelegt'
          : 'Teilnehmer bearbeitet';

      this.$store
        .dispatch(action, this.formatChildOutGoing(this.child))
        .then(() => {
          this.$root.$info
            .open({ msg, title: 'Good job!' })
            .then(response => {
              if (response) {
                this.cancel();
              }
            })
            .catch(error => {
              console.log(error);
              this.cancel();
            });
        })
        .catch(error => {
          this.$root.$info.open({ msg: error, title: 'Mist!' });
          this.cancel();
        });
    },

    formatChildIncoming(source) {
      const target = { id: source.id };
      target.parentData = source.parentData;
      target.medicData = source.medicData;
      target.childData = {
        firstName: source.firstName,
        familyName: source.familyName,
        birthDay: source.birthDay,
        gender: source.gender,
        attendance: source.attendance
      };
      target.additionalData = {
        additionalData: source.additionalData
      };

      /* enhance child with campData in case it doesn't exist yet */
      target.campData = source.campData
        ? source.campData
        : { group: '', room: '', bed: '' };

      return target;
    },

    formatChildOutGoing(source) {
      const target = { id: source.id };
      target.parentData = source.parentData;
      target.medicData = source.medicData;
      target.firstName = source.childData.firstName;
      target.familyName = source.childData.familyName;
      target.birthDay = source.childData.birthDay;
      target.gender = source.childData.gender;
      target.attendance = source.childData.attendance;
      target.additionalData = source.additionalData.additionalData;
      target.campData = source.campData;
      delete target.childData;

      return target;
    }
  }
};
</script>

<style scoped>
fieldset {
  border: 1px solid rgba(120, 130, 135, 0.2);
}
</style>
