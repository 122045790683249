<template>
  <v-row align="center" class="mr-2">
    <v-checkbox
      :input-value="myObj.flag"
      hide-details
      class="shrink mr-2 mt-0"
      @change="updateData({ flag: $event, data: myObj.data })"
    ></v-checkbox>
    <v-text-field
      :value="myObj.data"
      :disabled="!myObj.flag"
      :counter="maxlength"
      :maxlength="maxlength"
      :label="label"
      @input="updateData({ flag: myObj.flag, data: $event })"
    ></v-text-field>
  </v-row>
</template>

<script>
export default {
  props: {
    myObj: {
      type: Object,
      default() {
        return {};
      }
    },
    label: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: 0
    },
    rules: {
      type: String,
      default: ''
    },
    notRequired: {
      type: Boolean
    }
  },

  data() {
    return {
      clicked: null,
      changed: false,
      newInput: '',
      newCheckBox: null
    };
  },

  computed: {
    isDiabled() {
      return this.myObj.flag;
    }

    // modelObject() {
    //   debugger;
    //   return this.object;
    // },

    // modelInput: {
    //   // getter
    //   get() {
    //     if (this.changed) {
    //       return this.newInput;
    //     }
    //     return this.inPut;
    //   },
    //   // setter
    //   set(newInput) {
    //     this.changed = true;
    //     this.newInput = newInput;
    //   },
    // },
    // modelCheckBox: {
    //   // getter
    //   get() {
    //     if (this.clicked) {
    //       return this.clicked;
    //     }
    //     return this.checkBox;
    //   },
    //   // setter
    //   set(newValue) {
    //     this.clicked = newValue;
    //     this.newCheckBox = newValue;
    //   },
    // },
  },

  methods: {
    // eslint-disable-next-line no-unused-vars
    updateData(newObject) {
      this.$emit('update:myObj', newObject);
    }
  }
};
</script>
