<template>
  <v-text-field
    v-model="model"
    :counter="maxlength"
    :maxlength="maxlength"
    :label="label"
    dense
    required
    outlined
    :rules="getRules"
    @input="formatInput"
    @keyup="$emit('update:field', model)"
  >
    <v-icon
      v-if="!notRequired"
      slot="prepend"
      :color="model.length < 2 ? 'red' : 'green'"
      >mdi-star</v-icon
    >
  </v-text-field>
</template>

<script>
import { CONST_RULES_MAP } from '../../plugins/constants/Constants';

function initialState() {
  return {
    changed: false,
    newValue: ''
  };
}

export default {
  props: {
    field: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: 0
    },
    rules: {
      type: String,
      default: ''
    },
    notRequired: {
      type: Boolean
    }
  },

  data() {
    return initialState();
  },

  computed: {
    model: {
      // getter
      get() {
        if (this.changed) {
          return this.newValue;
        }
        return this.field;
      },
      // setter
      set(newValue) {
        this.changed = true;
        this.newValue = newValue;
      }
    },

    getRules() {
      return CONST_RULES_MAP.get(this.rules);
    }
  },

  methods: {
    formatInput() {
      if (this.label !== 'eMail') {
        this.model = this.model.charAt(0).toUpperCase() + this.model.slice(1);
      }
    }
  }
};
</script>
